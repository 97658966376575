import {
  useForm,
  getFormProps,
  getInputProps,
  getTextareaProps,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { useMediaQuery } from '#app/hooks/use-media-query';
import { useIsPending } from '#app/utils/misc';
import { ErrorList, Field, TextareaField } from './forms';
import { type FeedbackDialogAction } from './feedback-dialog.server.ts';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './ui/drawer';
import { StatusButton } from './ui/status-button';

export type FeedbackDialogProps = {
  trigger: React.ReactNode;
};

export const FeedbackFormSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  content: z.string(),
});

export function FeedbackDialog({ trigger }: FeedbackDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  const fetcher = useFetcher<FeedbackDialogAction>();
  const lastData = fetcher.data;
  const isPending = useIsPending();

  const [form, fields] = useForm({
    id: `feedback-form-${isOpen}`,
    constraint: getZodConstraint(FeedbackFormSchema),
    lastResult: lastData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: FeedbackFormSchema });
    },
    shouldRevalidate: 'onBlur',
  });

  const isDesktop = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    if (form.status === 'success') {
      setIsOpen(false);
    }
  }, [form.status, setIsOpen]);

  const formComponent = (
    <fetcher.Form
      method="POST"
      action="/resources/feedback"
      {...getFormProps(form)}
    >
      <HoneypotInputs />

      <div className="flex flex-col gap-2">
        <Field
          labelProps={{ children: 'Name' }}
          inputProps={{
            ...getInputProps(fields.name, { type: 'text' }),
            autoFocus: true,
          }}
          errors={fields.name.errors}
        />

        <Field
          labelProps={{ children: 'Email' }}
          inputProps={{
            ...getInputProps(fields.email, { type: 'text' }),
          }}
          errors={fields.email.errors}
        />

        <TextareaField
          labelProps={{ children: 'Feedback' }}
          textareaProps={{
            ...getTextareaProps(fields.content),
            rows: 10,
            className: 'resize-none',
          }}
          errors={fields.content.errors}
        />

        <ErrorList errors={form.errors} id={form.errorId} />
      </div>
    </fetcher.Form>
  );

  return isDesktop ? (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Have feedback?</DialogTitle>
          <DialogDescription>
            See something wrong with Kempo? I'm human, so please be nice and
            I'll do my best to fix it. Thanks for your support!
          </DialogDescription>
        </DialogHeader>

        {formComponent}

        <DialogFooter>
          <StatusButton
            form={form.id}
            status={isPending ? 'pending' : form.status ?? 'idle'}
            type="submit"
            disabled={isPending}
          >
            Send Feedback
          </StatusButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="px-4">
        <DrawerHeader>
          <DrawerTitle>Have feedback?</DrawerTitle>
          <DrawerDescription>
            See something wrong with Kempo? I'm human, so please be nice and
            I'll do my best to fix it. Thanks for your support!
          </DrawerDescription>
        </DrawerHeader>

        {formComponent}

        <DrawerFooter>
          <StatusButton
            form={form.id}
            status={isPending ? 'pending' : form.status ?? 'idle'}
            type="submit"
            disabled={isPending}
          >
            Send Feedback
          </StatusButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
